
            @import "@/assets/css/vars.scss";
          
























































































































.ShareIcon{
    display: flex;
    height: fit-content;
    .spanImg{
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        .aLink {
            img {
                height: 20px;
            }
        }
    }
    .lastImg{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .aDot {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 3px;
            img {
                display:block 
            }
        }
    }
}
