
            @import "@/assets/css/vars.scss";
          












































































































































































































.blog-list-wrapper {
  min-height: calc(100vh - 250px);
  max-width: 1275px;
  margin: 0 auto;
  padding: 0 6px;
  box-sizing: border-box;
  padding-top: 60px;
  text-align: cente;

  .content {
    display: flex;
   
  }
  .leftSide {
    position: relative;
    width:100%;

    .not_found_blog{
      // height: calc(100vh - 80px);
      padding-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      .wrapp{
        max-width: 100%;
        position: relative;
        img {
          max-width: 100%;
        }
        .text{
          font-weight: 700;
          font-size: 36px;
          text-align: center;
          color:var(--btn-color);
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }

  }
  .rightSide {
    // display: flex;
    padding-top: 10px;
    margin-left: auto;
    margin-left: 40px;
    position: sticky;
    top: 25px;
    position: -webkit-sticky;
    position: sticky;
    align-self: flex-start;
    background-color: var(--main-background-color);
  }
  .wrappTitle{
    width: fit-content;
    margin: auto;
    margin-bottom: 45px;
    .line{
      height: 7px;
      background-color: var(--btn-color);
    }
  }

  h1 {
    margin-bottom: 0px;
    font-size: 40px;
    line-height: 46px;
    color: var(--btn-color);
  }
  .show-more-wrapper {
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 120px;
    max-width: 735px;

    .show-more {
      cursor: pointer;
      font-size: 15px;
      line-height: 24px;
      color: var(--text-soft-color);
    }
  }
}
@media only screen and (max-width: 1350px){
  .blog-list-wrapper {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px;

    h1 {
      font-size: 34px;
    }
  }
}
@media only screen and (max-width: 1080px) {
  .content {
    flex-direction: column;

  .leftSide {
    order: 2;

  }
  .rightSide {
    // top: 0px;
    position: initial;
    order: 1;
    width: 100%;
    margin-left: 0;
    z-index:100;
    }
  }
}

@media only screen and (max-width: 500px){
  .blog-list-wrapper {
    padding-top: 22px;
    .wrappTitle{
      width: fit-content;
      margin: auto;
      margin-bottom: 35px;
      .line{
        height: 3px;
        background-color: var(--btn-color);
      }
    }

    h1 {
      font-size: 30px;
      // margin-bottom: 47px;
    }
  }
}
