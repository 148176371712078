
            @import "@/assets/css/vars.scss";
          















































































































a {
  text-decoration: none;
}
#index {
  position: relative;
}
.sample-report-wrapper {
  background: var(--header-background-color);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  z-index:110;


  @keyframes example {
    from {right: -200px;}
    to {right: 2760px;}
  }

  .sample-report {
    width: auto;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-color);
    cursor: default;
    position: absolute;
    animation-duration: 40s;
    animation-timing-function: linear;
    animation-name: example;
    animation-iteration-count: infinite;
    white-space: nowrap;
    animation-fill-mode: forwards;
    right: -200px;
  }
}

.header-wrapper {
  height: 80px;
  width: 100%;
  padding: 0 30px;
  max-width: calc(1440px + 60px);
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
   z-index:210;

  span {
    cursor: pointer;
    color: #4C4C52;
    margin-top: 8px;
  }
  #value-my-car {
    margin-left: 25px;
  }
  #blog {
    margin-left: 25px;
  }
  @media only screen and (max-width: 768px) {
    #how-it-works, #blog {
      display: none;
    }
  }
 
  .logo-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    #smallLogo {
      margin-top:-2px;
      width: 30px;
      display: none;
    }
    #bigLogo {
      width:120px;
      //height:25px;
      display: block;
    }
  }
  .rightSideHeader{
    display: flex;
  }
  .wrappProfileHeader{
     height: fit-content;
    display: flex;
    align-items: center;
  }
  @media only screen and (max-width: 450px) {
    .logo-wrapper #smallLogo {
      display: block;
    }
    .logo-wrapper #bigLogo {
      display: none;
    }
  }
}
 .background{
   position: relative;
   z-index:210 !important;
   background-color: var(--header-background-color);
 }


@media only screen and (max-width: 450px) {
  .disappearing {
    display: none;
  }
  .header-wrapper {
    padding: 0 16px;
  }
}
