
            @import "@/assets/css/vars.scss";
          















































































@import '@/assets/css/singUp.scss';
.wrapForgot { 
    display: flex;
    // width: 100vw;
    height: calc(100vh - 80px);
    justify-content: center;
    // align-items: center;
    padding-top: 87px;}

.enterPassword{
    width:406px;
    .title{
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        text-align: center;
        width: 100%;
        color: var(--title-color);
    }
    .txt{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: var(--text-color);
        margin-top: 44px;
        margin-bottom:23px;
    }

    .button{
        margin-top:30px;
        width:100%;
        box-sizing:border-box;
        height: 50px;
        background: var(--btn-color);
        border-radius: 5px;

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        color: var(--main-background-color);
    }
    .button:hover{
        filter: brightness(.9);
    }
}
@media only screen and (max-width: 500px){
   .enterPassword{
        max-width: 335px;
        margin:0 20px;
        .title{
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 40px;
            text-align: center;
        }
        .txt{
            font-size: 15px;
            line-height: 24px;
            margin-top: 39px;
            margin-bottom:22px;
        }

        .button{
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }
    }

}
