
            @import "@/assets/css/vars.scss";
          

















































































































































































.wrappD {
    .dropdown-wrapper {
        width: 25%;
        min-width: 308px;
        margin: 0;
        margin: 8px 0;

        .header-wrapper {
            border-radius: 25px;
        }

    }

    .wrappSearch {
        width: 25%;
        margin: 8px 0;
        height: 39px;
    }
}

@media only screen and(max: 870px) {
    .wrappD {
        .dropdown-wrapper {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            margin: 0;
            margin: 8px 0;
            box-sizing: border-box;

            .header-wrapper {
                border-radius: 25px;
            }

        }

        .wrappSearch {
            margin: 8px 0;
            height: 39px;
        }
    }

}
