
            @import "@/assets/css/vars.scss";
          





















































































































































































@import '@/assets/css/singUp.scss';

.wrappLeaveComment {
  margin-bottom: 25px;
  max-width: 627px;

  .title {
    // font-size: 13px;
    // // line-height: 36px;
    // letter-spacing: 0.02em;
    // text-transform: uppercase;
    // color: #9498A0;
    // margin-bottom: 3px;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    color: var(--title-color);
  }

  .txtInfo {
    padding-left: 20px;
    color: var(--text-soft-color);
  }

  .txt {
    font-size: 12px;
    // line-height: 36px;
    letter-spacing: 0.02em;
    color: var(--text-soft-color);
    // margin: 10px 0;
    text-align: center;
    min-height: 29px;

  }

  .sucessMsg {
    font-size: 12px;
    // line-height: 36px;
    letter-spacing: 0.02em;
    color: var(--btn-color);
    text-align: center;
  }

  .info {
    display: flex;
    justify-content: space-between;

  }

  .wrappTextArea {
    position: relative;


    .buttonFix {
      position: absolute;
      bottom: 18px;
      right: 21px;
      width: 30px;
      height: 30px;
      background-color: var(--btn-color);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  input,
  textarea {
    box-sizing: border-box;
    // width: 511px;
    width: 48%;
    height: 35px;
    border: 1px solid var(--border-color);
    border-radius: 25px;
    padding: 0 24px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;

    color: var(--text-darker);
  }
  textarea {
    font-family: 'Archivo';
    width: 100%;
    height: 141px;
    padding-top: 20px;
    padding-right: 52px;
  }

  .basicTextarea {
    margin-bottom: 0;
  }

  .textareaErr,
  .inputErr {
    border: 1px solid var(--err-color);
  }


  input::placeholder {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;

    color: var(--text-lighter);
  }



}
