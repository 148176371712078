
            @import "@/assets/css/vars.scss";
          














































@import '../assets/css/buttonStyle.scss';
.not_found {
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .top {
        width:fit-content;
        margin-bottom: 121px;
        .title{
            font-weight: 700;
            font-size: 26px;
            // line-height: 60px;
            color: var(--title-color);
            margin-bottom: 20px;
        }

    }
    .bottom {
        max-width: 100%;
        img{
            max-width: 100%;
        }

    }

}
