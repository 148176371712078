
            @import "@/assets/css/vars.scss";
          




































































































































@import '../../assets/css/buttonStyle.scss';

.wrappInput:nth-last-of-type(odd) {
   background-color: rgba(0, 0, 0, 0.2);
    // filter: brightness(0.75);

}
.wrappInput{
    display: flex;
    align-items: center;
    justify-content: center;
    .readonly{
        border: 1px solid transparent;
        margin: 5px 0;
        height: 39.48px;
        border-radius: 6px;
        outline: none;
        width: 350px;
        background-color: transparent;
        color: var(--text-color);
    }
    .input{
        margin: 5px 0;
        height: 39.48px;
        border: 1px solid var(--border-color);
        filter: drop-shadow(0px 1px 3px var(--border-color));
        border-radius: 6px;
        outline: none;
        width: 350px;
        background-color: var(--main-background-color);
        color: var(--text-color);

    }
    
    .wrapOptions{
        display: flex;
        align-items: center;
       
        .edit{
            width: 140px;
            display: flex;
        }

        .delete{
            margin-left: 30px;
            cursor: pointer;
        }
    }

    
}
.wrappButton{
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 50px;

        .buttonSend,
        .buttonCancel {
            width:40%;
        }
        
    }
