
            @import "@/assets/css/vars.scss";
          







































































.wrappSearch{
    // width: 511px;
    // height: 56px;
    // width: 211px;
    // height: 26px;

    max-width: 400px;
    width: 100%;
    height: 36px;
    position: relative;
    input {
        box-sizing: border-box;
        border: 1px solid var(--border-color);
        outline: none;
        border-radius: 25px;
        width:100%;
        height: 100%;
        padding: 0 10px;

        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        font-size: 14px;
        line-height: 17px;

        color: var(--text-color);
        background-color: var(--main-background-color);
    }
    input ::placeholder {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        font-size: 14px;
        line-height: 17px;

        color: var(--text-soft-color);

    }

    img{
        position: absolute;
        right: 10px;
        top: calc(50% - 6px);
        height: 13px;
    }
}

