
            @import "@/assets/css/vars.scss";
          





























































.login{
    background-color: var(--main-background-color);
    display: flex;
    height: calc(100vh - 80px);
    justify-content: center;
    align-items: center;
    
}
@media only screen and (max-width: 500px){
    .login{
        height: initial;
    }
}
