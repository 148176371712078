
            @import "@/assets/css/vars.scss";
          














































































































.wrappProfile{
    z-index:1000;
    height: calc(100vh - 180px);
    width:100% !important;
    box-sizing: border-box;
    background-color: var(--main-background-color);
    display:flex;
    margin: 0 auto;
    padding-top: 87px;
     .holdContent {
        box-sizing: border-box;
        padding: 0 30px;
        max-width: 1275px;
        width:1275px;
        margin: 0 auto;
        // min-height: 588px;
       
     }
}
 @media only screen and (max-width: 1305px){
     .wrappProfile{

         width:100%;
         display: flex;
         .holdContent{
            display: flex;
            width:100%;
            display: flex;
         }
     }
 }

// @media only screen and (max-width: 900px){
//      .wrappProfile{
//             //  flex-direction: column;
//             //  align-items: flex-start;
//             // height: 120px;
//             //  padding:22px 35px;
//           .holdContent{
//             box-sizing:border-box;
//             height: 120px;
//             padding:22px 15px;
//             padding: 0 10px;
//         }
//      }
// }
@media only screen and (max-width: 767px){
     .wrappProfile{
         height: initial ;
         padding-top: 47px;
        .holdContent{
            padding:0px 35px;
           
            height:initial;
        }
    }
}
@media only screen and (max-width: 750px){
     .wrappProfile{
         height: initial ;
        //  padding-top: 72px;
        .holdContent{
            padding:0px 16px;
            height:initial;
            width: 100%;
            box-sizing: border-box;
        }
    }
}

